import { createSignal, For, Show } from "solid-js";
import { createStore, } from "solid-js/store";
import { useNavigate } from "@solidjs/router";
import { AvatarWithWfButtons, showWfCandidateMatchSneakPreviewItems, showWfCandidateMatchNotSelectedItems } from '../SmallComponents/SmallAvatarWithWfButtons'

import { getCompanyLogo } from '../../../utils/getCompanyLogo'
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { SelectChangeEvent } from "@suid/material/Select";
import { isExtraSmall, isSmall, isMedium, isLarge, isExtraLarge } from "../../../utils/sizeUtil"

import { Button, Typography, Card, Stack, Grid, Alert, Avatar } from "@suid/material";

import { GREY_COLOR, CARD_MARGIN, CARD_DISPLAY, CARD_COLOR_L1, CARD_COLOR_L2, CARD_COLOR_L3 } from '../UIConstants';

import { CountrySelect } from '../SmallComponents/SmallFieldCountryCodeList'

import { DateInput } from '../SmallComponents/SmallFieldDate'
import { FieldSelectList } from '../SmallComponents/SmallFieldSelectList'
import { FieldTextValue } from '../SmallComponents/SmallFieldText'
import { FieldCurrencyValue } from '../SmallComponents/SmallFieldCurrencyValue'


import { PageListMenu } from '../SmallComponents/SmallListMenu';
import { customerMatchingMenu } from "../../../utils/menuUtil"

import { CandidateAvatar } from '../SmallComponents/SmallCandidatesList'
import { CandidateProgress } from '../SmallComponents/SmallCandidateProgress'
import { isDark } from '../AppMenuBar/AppBarToogleColorMode';
import { getDummyAvatar } from '../../../utils/getdummyavatar'
import { getText } from '../SmallComponents/Translate'

const CandidateMatchingComponent = () => {

    const navigate = useNavigate();
    const [open, setOpen] = createSignal(false);
    const handleOpen = () => {
        setOpen(true);
        navigate('/customerjobroleview')
    }
    const handleOpenCustomerAssignment = () => {
        navigate('/customerassignment')
    }
    const handleClose = () => setOpen(false);

    const [anchorEl, setAnchorEl] = createSignal<Element | null>(null);

    const openPopover = () => Boolean(anchorEl());
    const handleOpenSocialMedia = (event: { currentTarget: Element }) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseSocialMedia = () => {
        setAnchorEl(null);
    };

    const [rateValue, setRateValue] = createSignal("120");
    const handleRateValueChange = (newRate: string) => {
        setRateValue(newRate);
    };


    const [rateModel, setRateModel] = createSignal("Simple");
    const handleRateModel = (event: MouseEvent,
        newRateModel: string) => {

        setRateModel(newRateModel);
    };


    const [state, setState] = createStore({
        workModel: 'Remote',
        remoteWork: '3',
        rateModel: 'Simple',
        expenseModel: 'AllInclusive',
        fromDate: '2023-05-01',
        toDate: '2023-05-15',
        country: 'Denmark',
        language: 'Danish',
        company: ' ',
        calendarDays: 0,
        allocation: '8',
        allocationOnSite: '0',
        allocationRemote: '0',
        allocationUnits: 'Hours' || 'Days',
        allocationUnitsPr: 'Day' || 'Week' || 'Month',
        currency: 'EUR',
        ratePer: 'Hour' || 'Day' || 'Week' || 'Month',
        rateValue: 0,
        remoteRateValue: 0,
        onSiteRateValue: 0,
        expenseValue: 0,
        remoteExpenseValue: 0,
        onSiteExpenseValue: 0,
        totalHours: 0,
        totalOnSiteHours: 0,
        totalRemoteHours: 0,

        sumRates: '0.00',
        sumExpenses: '0.00',
        sumTotal: '0.00',
    });


    const handleChange = (key: keyof typeof state, newValue: any) => {
        setState(key, newValue);
    };

    const avatarNotSelected = [
        { wfStatus: "87%", locationCountry: "Denmark", locationCity: "Vejen", avatarName: "Nordisk Solar", jobName: "S/4 upgrade", jobDescription: "The best SAP S/4 project in Europe in 2022. Come and join.", src: getCompanyLogo("solar.com") },
        { wfStatus: "89%", locationCountry: "Germany", locationCity: "Berlin", avatarName: "Tesla", jobName: "Welcome music", role: "Senior Developer", src: getCompanyLogo("tesla.com") },
        { wfStatus: "82%", locationCountry: "USA", locationCity: "Boston", avatarName: "Meta Inc", src: getCompanyLogo("meta.com") },
        { wfStatus: "89%", locationCountry: "USA", locationCity: "San Franisco", avatarName: "Google", src: getCompanyLogo("google.com") },
        { wfStatus: "72%", locationCountry: "Germany", locationCity: "Waldorf", avatarName: "SAP A.G.", src: getCompanyLogo("sap.com") },
    ]
    const avatarNotReleased = [
        { anonymized: "nameAndAvatar", locationCountry: "UK", locationCity: "London", avatarName: "Netflix", jobName: "HR Upgrade", role: "Project Manager", jobDescription: "The best SAP S/4 project in Europe in 2022. Come and join.", src: "", jobStartDate: "Jun 2020", jobEndDate: "Dec 2023", onSiteText: "5 days onsite", wfStatus: "Candidates identified" },
    ]

    const [saving, setSaving] = createSignal(false);

    const [fromDate, setFromDate] = createSignal('2022-01-01');
    const [toDate, setToDate] = createSignal('2022-01-31');
    const handleFromDateChange = (value: string) => {
        setFromDate(value);
        // setSumCalendarDays(calculateDaysBetween(new Date(fromDate()), new Date(toDate())).toString())
    };
    const handleToDateChange = (value: string) => {
        setToDate(value);
        // setSumCalendarDays(calculateDaysBetween(new Date(fromDate()), new Date(toDate())).toString())
    };

    const [allocation, setAllocation] = createSignal('12');

    const handleAllocationUnitsChange = (value: string) => {
        setAllocationUnits(value);
    };

    const [currency, setCurrency] = createSignal("EUR");
    const handleCurrencyChange = (event: SelectChangeEvent) => {
        setCurrency(event.target.value);
    };

    const [ratePer, setRatePer] = createSignal("Hour");
    const handleRatePer = (newRatePer: string) => {
        setRatePer(newRatePer);
    };
    const [country, setCountry] = createSignal('');

    const [allocationUnits, setAllocationUnits] = createSignal("Hours");
    const [allocationUnitsPr, setAllocationUnitsPr] = createSignal("Days");

    const txtStatus = getText("default", "status")
    const txtWaitingInterview = getText("interviewcomponent", "waitinginterview")
    const txtMatchSimulation = getText("matchpage", "smartmatch")
    const txtBestMatch = getText("matchpage", "bestmatch")
    const txtAlertLowSupply = getText("matchpage", "alertlowsupply")
    const txtWfStatusIdentified = getText("workflowcomponent", "identified")
    const txtDays = getText("default", "days")
    const txtOnsite = getText("default", "onsite")
    const txtTbd = getText("default", "tbd")
    const txtNotSelected = getText("matchpage", "notselected")
    const txtNotSelectedJobs = getText("matchpage", "jobnotselected")
    const txtNotReleased = getText("matchpage", "notreleased")
    const txtMatchStatus = getText("matchpage", "matchstatus")
    const txtListNewOrder = getText("matchpage", "listneworder")
    const txtPriceUpdated = getText("matchpage", "priceupdated", [{ name: "name", value: "Henry Smith" }])
    const txtCountry = getText("default", "country")
    const selectDark = isDark()

    const [openFilter, setOpenFilter] = createSignal(false);
    const handleOpenFilter = () => {
        alert('handleOpenFilter')
        setOpenFilter(true);
    }

    //   <CountrySelect defaultValue={'da'} id="LanguageId" label={txtCountry() as string} type="Language" valueOnChange={(newValue) => handleChange('language', newValue)} />
    //    <FieldText id="companyNameId" label={"Company"} textValue="" valueOnChange={(newValue) => handleChange('company', newValue)} />                               

    return (

        <>



            {/* View section for matching */}
            <Card sx={{ margin: 1 }}>

                <Grid container {...isSmall() ? { direction: 'column' } : { direction: 'row' }} >

                    <Grid item xs={12} >
                        {/* Addition navigation options for the customer */}
                        <Stack
                            direction="row"
                            alignItems="left"
                            spacing={2}
                            sx={{ margin: 1, display: 'flex' }}
                        >
                            <PageListMenu options={customerMatchingMenu} handleEvent={handleOpenFilter} />

                        </Stack>

                        {/* Status of the matching process */}
                        <Alert sx={{ px: 1, py: 1 }} severity="info">
                            {txtMatchStatus()}
                        </Alert>
                    </Grid>


                    {/* Match simulation */}
                    <Grid item xs={12} >

                        <CardWithMinimize iconName="matching" header={txtMatchSimulation() as string} type="stack" defaultOpen={true}>

                            <Stack direction="column" margin={1} spacing={1} flexDirection="column" flexGrow={1}>

                                <Stack spacing={2} margin={2}  >
                                    <Typography color="text.secondary"> Use one or more of the fields to add your requirements.</Typography>

                                    <Stack direction={isExtraSmall() ? "column" : "row"} spacing={2}  >
                                        <FieldSelectList saving={saving()} id="roleListId" label={"Role"} type="role" />
                                        <FieldSelectList saving={saving()} id="industryListId" label={"Industry"} type="industry" />
                                    </Stack>
                                    <Stack direction={isExtraSmall() ? "column" : "row"} spacing={2}  >
                                        <FieldSelectList saving={saving()} id="technicalListId" label={"Technical"} type="technical" />
                                        <FieldSelectList saving={saving()} id="methodsListId" label={"Methods"} type="method" />
                                    </Stack>

                                    <Stack direction={isExtraSmall() ? "column" : "row"} spacing={2}>
                                        <DateInput id="fromDateId" label={"Start date"} onChange={(newValue) => handleChange('fromDate', newValue)} />
                                        <CountrySelect defaultValue={'da'} id="countryId" label={txtCountry() as string} type="Country" valueOnChange={(newValue) => handleChange('country', newValue)} />
                                        <Stack spacing={2} direction="row" alignItems="center">
                                            <FieldCurrencyValue id="valueId" label="From Value" />     <Typography color="text.secondary"> Per Hour</Typography>
                                        </Stack>
                                    </Stack>
                                    <FieldTextValue id="aiPromptId" fullWidth={true} multiline={true} label={"AI Prompt"} textValue="" valueOnChange={(newValue) => handleChange('company', newValue)} />



                                </Stack>

                                {/* Section for simulation of input to the AI*/}
                                <Card sx={{ backgroundColor: (selectDark ? CARD_COLOR_L2 : GREY_COLOR) }} >
                                    <Stack margin={1} spacing={2} >



                                    </Stack>
                                </Card>

                                <Alert sx={{ px: 1, py: 1 }} severity="warning" >
                                    {txtAlertLowSupply()}
                                </Alert>

                            </Stack>

                        </CardWithMinimize>

                        {/* -- End of simulation part -- */}

                    </Grid>


                    {/* Best match */}
                    <Grid container direction='row'  >

                        <CardWithMinimize iconName="heart" header={txtBestMatch() as string} type={isSmall() || isMedium() ? "list" : "stack"} defaultOpen={true}>



                            {/* Match #1 */}
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Stack sx={{ alignItems: 'center' }}  >
                                    <Button onclick={handleOpenCustomerAssignment}>
                                        <Avatar sx={{ width: 56, height: 56, bgcolor: '#8385C2' }}>1</Avatar>
                                    </Button>
                                    <CandidateProgress />

                                    <Typography variant="body1" sx={{ px: 1, fontSize: 14 }} color="text.secondary"  > {txtStatus()} </Typography>
                                    <Typography variant="body1" sx={{ px: 1, fontSize: 14 }} color="success.dark"> Identified</Typography>

                                </Stack>
                                <CandidateAvatar
                                    layout="grid"
                                    candidateName="EG"
                                    jobName="Project manager working out of Münich. S/4 Upgrade. 12 EU countries in scope. He served cusomers as e.g. Coca-Cola, Audi with agile project management"
                                    jobRole="Project Manager"
                                    logoUrl={""}
                                    rateValue={120}
                                    rateCurrency='USD'
                                    rating={3.8}
                                    jobStartDate="Jun 2020" jobEndDate="Dec 2023"
                                    matchPct={87}
                                    wfStatus="Identified"
                                    onSiteText="5 days onsite"
                                />


                            </Grid>
                            {/* Match #2 */}
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Stack sx={{ alignItems: 'center' }} >
                                    <Button onclick={handleOpenCustomerAssignment}  >
                                        <Avatar sx={{ width: 56, height: 56, bgcolor: '#8385C2' }} >2</Avatar>
                                    </Button>
                                    <CandidateProgress />

                                    <Typography variant="body1" sx={{ px: 1, fontSize: 14 }} color="text.secondary"  > Status </Typography>
                                    <Typography variant="body1" sx={{ px: 1, fontSize: 14 }} color="success.dark"> Contract Sendt</Typography>

                                </Stack>
                                <CandidateAvatar
                                    layout="grid"
                                    candidateName="Teddy Weelly"
                                    jobName="Teddy is a very experienced project manager working out of Münich. He has a master in business science from Münich university. He served cusomers as e.g. Coca-Cola, Audi with agile project management"

                                    jobRole="Project Manager"
                                    logoUrl={getDummyAvatar()}
                                    rateValue={120}
                                    rateCurrency='USD'
                                    rating={3}
                                    jobStartDate="Jun 2020" jobEndDate={txtTbd() as string}
                                    matchPct={78}
                                    wfStatus={txtWfStatusIdentified() as string}
                                    onSiteText={txtTbd() as string}
                                />
                            </Grid>


                        </CardWithMinimize>




                    </Grid>
                </Grid>


                <Grid container direction='row' >

                    {/* Sneak preview */}
                    <Grid item xs={12} >
                        <CardWithMinimize header={txtNotReleased() + " (" + avatarNotReleased.length + ")"} type="stack" defaultOpen={false}>
                            <Stack direction="column" margin={2}>
                                <Typography color="text.secondary"> Some jobs are beeing prepared but not yet released for the public. The jobs can be for your interest and your are welcome to put a notification on the job.</Typography>
                                <Stack direction="row" >
                                    <For each={avatarNotReleased}>
                                        {(item) => <AvatarWithWfButtons showWfItems={showWfCandidateMatchSneakPreviewItems} wfStatus={item.wfStatus} avatarName={item.avatarName} jobName={item.jobName} role={item.role} src={item.src} jobDescription={item.jobDescription} locationCountry={item.locationCountry} locationCity={item.locationCity} anonymized={!item?.anonymized ? "no" : "nameAndAvatar"} avatarSize="medium" />}

                                    </For>
                                </Stack>
                            </Stack>
                        </CardWithMinimize>
                    </Grid>

                    {/* Not selected candidates */}
                    <Grid item xs={12} >
                        <CardWithMinimize header={txtNotSelectedJobs() + " (" + avatarNotSelected.length + ")"} type="stack" defaultOpen={false}>
                            <For each={avatarNotSelected}>
                                {(item) => <AvatarWithWfButtons showWfItems={showWfCandidateMatchNotSelectedItems} locationCountry={item.locationCountry} locationCity={item.locationCity} jobDescription={item.jobDescription} wfStatus={item.wfStatus} avatarName={item.avatarName} jobName={item.jobName} role={item.role} src={item.src} avatarSize="medium" />}
                            </For>
                        </CardWithMinimize>
                    </Grid>

                </Grid>

            </Card>
            <br />
            <br />
        </>
    );
}

export { CandidateMatchingComponent }