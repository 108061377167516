import { PageTemplate } from './PageTemplate'
import { WFMatchingButtons } from '../components/Workflow/WfActionsComponent';
import { CandidateMatchingComponent } from '../components/Candidate/CandidateMatchingComponent'

const ShowCandidateMatchingPage = () => {
    return (
        <PageTemplate
            type="candidate" headerPage="quicklauncher" headerElement="matching"
            leftPaneComponent={null} mainComponent={CandidateMatchingComponent()} buttonComponent={WFMatchingButtons()} >
        </PageTemplate>
    );
}
export { ShowCandidateMatchingPage }